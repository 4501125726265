<template>
  <div>
    <CRow>
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <strong>Редактировать Нитку</strong>
          </CCardHeader>
          <CCardBody>
            <pre></pre>
            <CForm @submit.prevent="updateLine()">
              <CInput
                      type="hidden"
                      name="action"
                      v-model="form.action"
                      required
              />
              <CInput
                      type="hidden"
                      name="action"
                      v-model="form.id"
                      required
              />
              <CInput
                      description="Название Нитки"
                      label="Введите название Нитки"
                      v-model="form.line_name"
                      required
              />
              <CInput
                      type="number"
                      description="Введите порядковый номер Нитки"
                      label="Введите порядковый номер Нитки"
                      v-model="form.line_order"
                      required
              />
                <label>Выберите ГРС</label>
                <model-list-select :list="grsDatas"
                                   v-model="form.grs_id"
                                   option-value="value"
                                   option-text="label"
                                   placeholder="Выберите ГРС">
                </model-list-select>

                <br>
                <label>Выберите тип линии</label>
                <model-list-select :list="lineTypes"
                                   v-model="form.line_type"
                                   option-value="value"
                                   option-text="label"
                                   placeholder="Выберите тип линии">
                </model-list-select>

                <br>
                <div class="form-group">
                  <input type="checkbox" id="delete_line" :value="0" v-model="form.delete" class="mr-2">
                  <label for="delete_line" class="form-check-label">Удалить</label>
                </div>
                <br>
                <br>

                <CInput
                        description="Введите координаты [Широта,Долгота]"
                        label="Координаты"
                        v-model="form.cord"
                />
                <div class="form-group">
                  <input type="checkbox" id="show_on_map" :value="0" v-model="form.show_on_map" class="mr-2">
                  <label for="show_on_map" class="form-check-label">Выводить на карте</label>
                </div>
                <br>
              <CButton type="submit" size="sm" color="primary"><CIcon name="cil-check-circle"/> Сохранить</CButton>
            </CForm>
          </CCardBody>
          <CCardFooter>
          </CCardFooter>
        </CCard>
      </CCol>

    </CRow>
  </div>
</template>

<script>
  import axios from 'axios'
  import {ModelListSelect} from "vue-search-select";
  import 'vue-search-select/dist/VueSearchSelect.css'

  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  export default {
    name: 'Line_s',
    data: function() {
      return {
        activeLineId: 0,
        apiDatas: [],
        grsDatas: [],
        operatorDatas: [],
        lineTypes:[],
        tableFields: ['id', 'name', 'grs_name', 'line_order', 'line_type'],
        form: {
          action: 'add_line',
          id: "",
          line_name: "",
          grs_id: "",
          line_order: "",
          line_type: "",
          delete: 0,
          cord: "",
          show_on_map: 0,
        },
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler (route) {
          if (route.query && route.query.page) {
            this.activePage = Number(route.query.page)
          }
        }
      }
    },
    methods: {
      updateLine () {

        let bodyFormData = new FormData();

        bodyFormData.append('action', "update_line");
        bodyFormData.append('line_id', this.activeLineId);
        bodyFormData.append('line_name', this.form.line_name);
        bodyFormData.append('grs_id', this.form.grs_id);
        bodyFormData.append('line_order', this.form.line_order);
        bodyFormData.append('line_type', this.form.line_type);
        bodyFormData.append('delete', this.form.delete ? 1 : 0);
        bodyFormData.append('show_on_map', this.form.show_on_map ? 1 : 0);
        bodyFormData.append('cord', this.form.cord);

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then(() => {

            this.$router.push({path: `/lines`})
            //Perform Success Action
            // this.getDataFromApi()
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
      pageChange (val) {
        this.$router.push({ query: { page: val }})
      }
    },
    created() {
      this.$watch(
        () => this.$route.params
      )
    },
    mounted() {
      if (this.$route.meta.lid === undefined) {
        this.activeLineId = getCookie('activeLineId');
      } else {
        document.cookie = `activeLineId=${this.$route.meta.lid}`
        this.activeLineId = this.$route.meta.lid;
      }

      axios
        .get(`${axios.defaults.baseURL}?action=get_line&id=${this.activeLineId}`)
        .then((response) => {
          this.form.line_name = response.data[0].line_name;
          this.form.grs_id = response.data[0].grs_id;
          this.form.line_order = response.data[0].line_order;
          this.form.line_type = response.data[0].line_type;
          if (response.data[0].show_on_map > 0) {
              this.form.show_on_map = response.data[0].show_on_map;
          }
          if (response.data[0].cord !== undefined) {
              this.form.cord = response.data[0].cord;
          }
        })
      axios
        .get(`${axios.defaults.baseURL}?action=get_allgrses`)
        .then((response) => {
          response.data.map((el) => {
            this.grsDatas.push({
              value: el.id,
              label: el.name
            })
          });
        })
      axios
        .get(`${axios.defaults.baseURL}?action=get_all_linetypes`)
        .then((response) => {
          response.data.map((el) => {
            this.lineTypes.push({
              value: el.id,
              label: el.name
            })
          });
        })
    },
    components: {
      ModelListSelect
    }
  }
</script>
